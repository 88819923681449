import React from 'react'
import CategoryNav from './../Layout/CategoryNav';
 function Categories(props) {
    // props.history.push('/');
  return (
    <div>
    <CategoryNav />
    </div>
  )

}
export default (Categories);