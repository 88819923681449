import React from 'react'

export default function Site() {
  return (
    <div className="site">
  

  <div class="BeOpWidget" data-content="629f1004c1a23d17afd1584e"></div>
    </div>
  )
}
