import React from 'react'


export default function Footer() {
  return (
    <div className="footer">
 
 <p> <a href="https://cloud.unlimitednow.live/login"> Dashboard</a> </p>Subscriptions<a href="https://cloud.unlimitednow.live/login">Login/ Signup</a><a href="https://blog.unlimitednow.site">Home</a>
 
     
    </div>
  )
}
