export const FetchPostsApi = () => {
    return (dispatch) => {
            const Cosmic = require('cosmicjs')
            var api = new Cosmic();
            var bucket = api.bucket({
              slug: process.env.REACT_APP_COSMIC_BUCKET || 'f4b367c0-d74b-11ec-b04d-09a204c9857b',
              read_key: process.env.REACT_APP_COSMIC_READ_KEY || 'wFJ9aO45KYZ5b39EARSJ8iPF2nhrsrCR4ghWqpdvSdypwQqOkB'
            })
            bucket.getObjects().then(data => {          
            dispatch({type:"FETCH_POSTS", data:data});            
            }).catch(err => {
              console.log(err)
            })

    }
}
